:root {
  /* COLORS */
  --primary-color: #4fd4a8;

  --white-100: #fafafa;

  --green-100: #f0f8f5;
  --green-200: #e5f5f0;
  --green-400: rgb(176, 226, 210);
  --green-500: rgb(119, 188, 165);
  --green-900: #3c584f;

  --grey-50: #f6f6f6;
  --grey-100: #dbdbdb;
  --grey-400: #999;
  --grey-700: #616161;

  --grey-hover: rgba(177, 177, 177, 0.51);

  --blue-200: #7c94af;
  --blue-300: #e6ecf3;
  --blue-400: #c2d4e7;
  --blue-500: #c2d5e9;
  --blue-600: #4c87c6;
  --blue-800: #405b7a;
  --blue-900: #2c4159;

  --red-secondary: rgb(245, 0, 87);
  --red-400: rgb(208, 2, 27);
  --red-200: rgb(225, 131, 141);
  --red-100: rgb(233, 172, 179);

  --palette-yellow: #fcdf9d;
  --palette-orange: #fcc0a0;
  --palette-pink: #fca0d2;
  --palette-purple: #d4b5fc;
  --palette-indigo: #bcb5fa;
  --palette-blue: #8ee1fc;
  --palette-green: #aef3d0;
  --palette-grey: #e5e6e5;
  --palette-palegreen: #adf8a0;
  --palette-aquamarine: #8dfcee;
  --palette-darkblue: #78a8f9;

  --default-link-line-color: #555555;
  --default-icon-color: #767676;

  /* Mui */
  --default-hover: rgba(0, 0, 0, 0.08);
  --default-button-text-color: rgba(0, 0, 0, 0.54);

  /* SPACES */
  --space-100: 8px;
  --space-200: 16px;
  --space-300: 24px;
  --space-400: 32px;
  --space-500: 40px;
  --space-600: 48px;
  --space-700: 56px;
  --space-800: 64px;
  --space-900: 72px;

  --sprint-width: 128px;

  --zones-side-menu-width: 56px; /*constant used in MillestoneMarkerZone.view*/
  --sprints-header-height: 59px;

  --gantt-bar-height: 38px;
  --gantt-bar-border-top: 5px;
  --gantt-bar-margin: 5px;

  --timeline-height: 39px;
  --milestones-height: 39px;
  --zones-menu-width: 56px;
  --expanded-zones-menu-width: 250px;
  --closed-dependencies-height: 72px;
  --card-editor-height: 117px;
  --add-sprint-width: 40px;
  --header-height: 48px;
  --history-drawer-width: 300px;
  --history-item-name-input-width: 210px;

  /* Z-INDEXES */
  --timeline-z-index: 50;
  --side-menu-z-index: 49;

  /* FONTS */
  --main-font: Lato;

  /*Mui*/
  --grey-icon: rgba(0, 0, 0, 0.54);
}

body {
  font-family: var(--main-font), sans-serif;
}

@media print {
  body {
    height: unset;
    line-height: unset;
  }
}

a {
  color: var(--primary-color);
}

.clickable {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
}
