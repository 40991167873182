.root {
  display: flex;
  flex-direction: column;
  height: 100%;

  .pageContent {
    flex: 1;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
  }
}

@media print {
  .root {
    .pageContent {
      overflow-y: unset;
    }
  }
}
